<template>
  <div
    class="plat-adv-container"
    v-lazy:background-image="{src: require('../../../assets/images/bg4.png')}"
  >
    <div class="page-outter">
      <div align="center">
        <img
          class="plat-adv-title"
          v-lazy="require('../../../assets/images/title11.png')"
        />
        <span class="plat-adv-desc">精细管控，满足仓储管理多样化需求</span>
        <div class="plat-adv-inner">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div class="plat-adv-dd">
                <div class="plat-adv-item">
                  <img v-lazy="item.icon" />
                </div>
                <span class="plat-adv-item-title">
                  {{item.title}}
                </span>
                <span
                  class="plat-adv-item-s1"
                  v-html="item.name"
                ></span>
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformAdvantage-smw",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon16.png"),
          title: "物码对应",
          name: `一物一码，可快速完成出入库<br>保证准确率，同时能对各个<br>环节作业流程进行溯源`,
        },
        {
          icon: require("../../../assets/images/app-icon17.png"),
          title: "库位功能管理",
          name: `有效完成对商品的库位管理<br>优化空间利用<br>提高运营效率`,
        },
        {
          icon: require("../../../assets/images/app-icon18.png"),
          title: "库存管理",
          name: `分析库存状态，优化拣货策略<br>堆压货品及时提醒<br>使库存结构更加合理`,
        },
        {
          icon: require("../../../assets/images/app-icon4.png"),
          title: "大数据分析",
          name: `提供精准的数据报告<br>为采购及营销提供决策依据`,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.plat-adv-container {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  width: 100%;
  padding-bottom: 2.4rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.plat-adv-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.plat-adv-desc {
  display: block;
  font-size: 0.3rem;
  color: #d7d8d7;
  margin-top: 0.2rem;
}
.plat-adv-inner {
  display: flex;
  justify-content: center;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: -0.8rem;
    li {
      float: left;
      margin-left: 0.8rem;
      margin-top: 1.8rem;
    }
  }
}
.plat-adv-dd {
  width: 5.8rem;
}
.plat-adv-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid #dcdadd;
  border-radius: 2.4rem;
  img {
    width: 1.6rem;
  }
}
.plat-adv-item-title {
  display: block;
  font-size: 0.4rem;
  color: #fff;
  margin-top: 0.45rem;
}
.plat-adv-item-s1 {
  display: block;
  font-size: 0.36rem;
  color: #dcdadd;
  line-height: 1.7;
  margin-top: 0.26rem;
}
</style>